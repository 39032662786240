import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  Navigate,
  Outlet,
} from 'react-router-dom';

import {
  adminUsersLoader,
  booksLoader,
  friendsLoader,
  gamePlatformsLoader,
  integrationsLoader,
  invitationsLoader,
  moviesLoader,
  settingsLoader,
  userPreferencesLoader,
} from '~/loaders';
import Header from '~/components/Header';
import Navigation from '~/components/Navigation';

import VideoGames from '~/pages/VideoGames';
import FriendGames from '~/pages/VideoGames/FriendGames';
import Shows from '~/pages/Shows';
import Movies from '~/pages/Movies';
import Music from '~/pages/Music';
import Books from '~/pages/Books';
import Uploads from '~/pages/Uploads';
import Downloads from '~/pages/Downloads';

import IntegrationsPage from '~/pages/Integrations';
import InvitationsPage from '~/pages/Invitations';
import FriendsPage from '~/pages/Friends';
import AdminPage, { AssumeControlBanner } from '~/pages/Admin';
import StyleguidePage from '~/pages/Styleguide';
import SettingsPage from '~/pages/Settings';

import LogoutPage from '~/pages/Logout';
import LoginPage from '~/pages/Login/Login';
import RegistrationPage from '~/pages/Registration';
import PasswordResetPage from '~/pages/PasswordReset';
import AuthorizedRoute from '~/components/AuthorizedRoute';

import { Colors } from '~/components/shared/Theme';
import SearchContextProvider from './SearchContext';

const Layout = () => {
  return (
    <SearchContextProvider>
      <div className="app-container">
        <AssumeControlBanner />
        <Header />
        <div className="content-area ios-scroll relative bg-background overflow-y-auto pb-20 md:pb-0">
          <Outlet />
        </div>
        <Navigation />
      </div>
    </SearchContextProvider>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/colors" element={<Colors />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegistrationPage />} />
      <Route path="/logout" element={<LogoutPage />} />
      <Route path="/reset" element={<PasswordResetPage />} />
      <Route element={<AuthorizedRoute />}>
        <Route element={<Layout />}>
          <Route path="/styleguide" element={<StyleguidePage />} />
          <Route path="/uploads" element={<Uploads />} />
          <Route path="/downloads" element={<Downloads />} />
          <Route
            path="/invitations"
            element={<InvitationsPage />}
            loader={invitationsLoader}
          />
          <Route
            path="/friends"
            element={<FriendsPage />}
            loader={friendsLoader}
          />
          <Route
            path="/admin"
            element={<AdminPage />}
            loader={adminUsersLoader}
          />
          <Route
            path="/settings"
            element={<SettingsPage />}
            loader={settingsLoader}
          />
          <Route path="/users/:id/media" element={<FriendGames />} />
          <Route path="/users/:id/games" element={<FriendGames />} />
          <Route path="/movies/*" element={<Movies />} loader={moviesLoader} />
          <Route path="/music" element={<Music />} />
          <Route path="/shows" element={<Shows />} />
          <Route
            path="/games/*"
            element={<VideoGames />}
            loader={async () => {
              const { data: platformData } = await gamePlatformsLoader();
              const gameFilters = await userPreferencesLoader();

              return { platformData, gameFilters };
            }}
          />
          <Route path="/books/*" element={<Books />} loader={booksLoader} />
          <Route
            path="/integrations"
            element={<IntegrationsPage />}
            loader={integrationsLoader}
          />
          <Route path="/" element={<Navigate to="/games" />} />
        </Route>
      </Route>
    </>,
  ),
);

function App() {
  return (
    <div className="h-full bg-background">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
